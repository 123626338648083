import React from "react"
import styled from "../../config"
import { Flex } from "reflexbox"
import Blob from "../../components/Blob"
import { Link } from "gatsby"

const Specialisaties = () => (
  <SpecialisatiesWrapper>
    <Blob cheight="250px" />
    <Flex justifyContent="center" py={5}>
      <h1>Specialisaties</h1>
    </Flex>
    <Lijst>
      <StyledLink to="/brainspotting">Brainspotting</StyledLink>
      <StyledLink to="/angsten">Angsten</StyledLink>
      <StyledLink to="/burn-out">Burn-out</StyledLink>
      <StyledLink to="/zelfvertrouwen">Zelfvertrouwen</StyledLink>
      <StyledLink to="/hartcoherentie">Hartcoherentie</StyledLink>
    </Lijst>
  </SpecialisatiesWrapper>
)

export default Specialisaties

const SpecialisatiesWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px;
  min-height: 600px;
`

const StyledLink = styled(Link)`
  color: black;
  font-family: "Comfortaa", sans-serif;
  padding: 50px;
  margin: 1em;
  box-shadow: 2px 3px 12px 2px #0000001f;
  border-radius: 15px;
`

const Lijst = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 50px;
`
