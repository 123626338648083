import React from "react"

import GlobalCollection from "../config/GlobalCollection"
import Specialisaties from "../modules/specialisaties/Specialisaties"

const SpecialisatiesPage = () => (
  <GlobalCollection>
    <Specialisaties />
  </GlobalCollection>
)

export default SpecialisatiesPage
